
import Vue from 'vue';
import {API} from './axios/index';
export default Vue.extend({
  name: 'App',

  data:()=>{
    return{
      showDropDownSearch: false,
      autoLoginLoading: false,
      keyword: '',
    }
  },
  methods:{
    // 切换黑暗模式并保存黑暗模式到localStorage
    saveDarkMode(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      window.localStorage.setItem("dark", this.$vuetify.theme.dark?"true":"false");
    },

    // 返回主页
    goHome(){
      this.$router.push("/");
    },

    // 去登陆
    goLogin(){
      this.$router.push("/login");
    },

    // 去我的页面
    goMy(){
      this.$router.push("/my");
    },

    // 自动登录
    autoLogin(){
      if(this.$store.state.sessionID=='' && window.localStorage.getItem("sessionID")){
        this.autoLoginLoading = true;
        API("user/autoLogin.php",{
          sessionID: window.localStorage.getItem("sessionID")
        }).then((res)=>{
          this.$store.commit("setUser", res);
        }).finally(()=>{
          this.autoLoginLoading = false;
        }).catch(()=>{
          window.localStorage.removeItem("sessionID");
        })
      }
    },
    setBackground(){
      this.$store.commit("setBackground", !this.$store.state.displayBackground);
      localStorage.setItem("displayBackground", this.$store.state.displayBackground?"true":"false");
    },
    setFullWidth(){
      this.$store.commit("setFullWidth", !this.$store.state.displayFullWidth);
      localStorage.setItem("displayFullWidth", this.$store.state.displayFullWidth?"true":"false");
    },
    goSearch(){
      this.$router.push("/search/"+this.keyword);
    },

  },
  mounted(){
    // 设置黑暗模式
    if(window.localStorage.getItem("dark")){
      this.$vuetify.theme.dark = window.localStorage.getItem("dark") == "true";
    }
    // 设置背景
    if(window.localStorage.getItem("displayBackground")){
      this.$store.commit("setBackground", window.localStorage.getItem("displayBackground") == "true");
    }
    // 设置全宽
    if(window.localStorage.getItem("displayFullWidth")){
      this.$store.commit("setFullWidth", window.localStorage.getItem("displayFullWidth") == "true");
    }
    // 设置预设模式
    if(window.localStorage.getItem("presetGridMode")){
      this.$store.commit("setPresetGridMode", parseInt(window.localStorage.getItem("presetGridMode")!));
    }
    // 自动登录
    this.autoLogin();
  },
  computed:{
    logined:function(){
      return this.$store.state.sessionID != "";
    }
  }
});
