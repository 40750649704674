import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

interface UserData{
  sessionID: string,
  userName: string,
  role: 'user' | 'admin',
  email: string
}

export default new Vuex.Store({
  state: {
    sessionID: "", // 判断是否登录
    userName: "",
    email: "",
    role: "",
    presetCompareMode: 0,
    displayBackground: false,
    displayFullWidth: false,
    presetGridMode: 0,
  },
  getters: {
  },
  mutations: {
    setUser(state, data: UserData){
      state.sessionID = data.sessionID;
      state.userName = data.userName;
      state.role = data.role;
      state.email = data.email;
    },
    setUserName(state, userName:string){
      state.userName = userName;
    },
    setPresetCompareMode(state, mode){
      state.presetCompareMode = mode
    },
    setBackground(state, displayBackground:boolean){
      state.displayBackground = displayBackground
    },
    setFullWidth(state, displayFullWidth:boolean){
      state.displayFullWidth = displayFullWidth
    },
    setPresetGridMode(state, mode:number){
      state.presetGridMode = mode
    }

  },
  actions: {
  },
  modules: {
  }
})
