
import Vue from "vue";
import { GetJson, ImageSize } from "../axios/index";
interface ICover {
  id: number;
  name: string;
  cover: string;
  inGShade: number;
  rating?: number;
  tags: string[];
  type: string;
  view?: number;
}
interface IItem {
  id: number;
  cover: string;
  name: string;
  inGShade: number;
}
interface IRank {
  all: {
    rating: IItem[];
    visit: IItem;
  };
  oneMonth: {
    rating: IItem[];
    visit: IItem;
  };
  sevenDays: {
    rating: IItem[];
    visit: IItem;
  };
}
interface IChineseShaders {
  version: string;
  createTime: string;
}
interface ILinks {
  id: number;
  name: string;
  url: string;
}
interface IResult {
  cover: ICover[];
  preset: IRank;
  presetGroup: IRank;
  chineseShaders: IChineseShaders,
  links: ILinks[]
}
export default Vue.extend({
  name: "HomeView",
  components: {},
  data() {
    return {
      loading: true,
      loadFail: false,
      cover: [] as ICover[],
      preset: {} as IRank,
      presetGroup: {} as IRank,
      chineseShaders: {} as IChineseShaders,
      links: [] as ILinks[],
      coverIndex: 0,
      coverLink: [
        { name: "预设", link: "/search/@预设", icon: "far fa-file-code" },
        { name: "预设集", link: "/search/@预设集", icon: "fa-book" },
        { name: "中文着色器", link: "/chineseShader", icon: "fa-language" },
        { name: "相关链接", link: "/link", icon: "fa-link" },
      ],
      order: [0, 0],
      time: [0, 0],
      orderItem: ["rating", "visit"], // 排序依据
      timeItem: ["sevenDays", "oneMonth", "sevenDays"], // 时间范围
      tags: [] as string[],
      tagLoading: false,
      tagError: false,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      GetJson("cover")
        .then((res: IResult) => {
          this.cover = res.cover;
          this.preset = res.preset;
          this.presetGroup = res.presetGroup;
          this.chineseShaders = res.chineseShaders;
          this.links = res.links;
        })
        .catch((err: any) => {
          this.loadFail = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    previewImage(url: string, size: string) {
      //如果以http开头，直接返回
      if (url == "") {
        return "";
      }
      if (url.startsWith("http")) {
        return url;
      } else {
        return ImageSize(url, size);
      }
    },
    getTags(){
      this.tagLoading = true;
      GetJson("tags")
        .then((res: string[]) => {
          // 选最后二十个
          this.tags = res.slice(-20);

        })
        .catch(() => {
          this.tagError = true;
        })
        .finally(() => {
          this.tagLoading = false;
        });
    }
  },
  computed: {
    coverHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 300;
        case "sm":
          return 300;
        case "md":
          return 500;
        case "lg":
          return 500;
        case "xl":
          return 500;
      }
      return 600;
    },
  },
  mounted() {
    this.getData();
    this.getTags();
  },
});
