import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const lightShades = "#F3F7F4";
const lightAccent = "#6a8496";
const brandColor = "#89211C";
const darkAccent = "#BE4456";
const darkShades = "#1E131D";


export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: brandColor,
        secondary: lightAccent,
        shades: lightShades,
        barBg: "#ffffff"
      },
      dark: {
        primary: brandColor,
        secondary: darkAccent,
        shades: darkShades,
        barBg: "#202020"
      },
    },
  },
});
