import axios from "axios";
import Vue from "vue";

type IAny={
  [x:string]: string
}



export function API(url: string, data: object, successToast=false){
  return new Promise((resolve:(value:any)=>void, reject)=>{
    axios({
      method: 'post',
      url: 'https://g.nightship.cn/api/' + url,
      data: data
    }).then((res)=>{
      if(res.status == 200){
        const result = res.data
        if(result.status == "success"){
          if(successToast){
            Vue.$toast.success("操作成功");
          }
          resolve(result.data);
        }else{
          Vue.$toast.error("操作失败："+result.errMsg);
          reject(result.errMsg);
        }
      }
      else{
        Vue.$toast.error("网络错误："+res.status);
        reject(res.status)
      }
    }).catch((err)=>{
      Vue.$toast.error("网络错误："+err.message);
      reject(err);
    })

  })
}

export function GetJson(name:string){
  return new Promise((resolve:(value:any)=>void, reject)=>{
    axios({
      method: 'get',
      url: 'https://g.nightship.cn/api/json/getJson.php?name=' + name,
    }).then((res)=>{
      if(res.status == 200){
          resolve(res.data);
      }
      else{
        Vue.$toast.error("网络错误："+res.status);
        reject(res.status)
      }
    }).catch((err)=>{
      Vue.$toast.error("网络错误："+err.message);
      reject(err);
    })

  })
}
export function GetFile(name:string){
  return new Promise((resolve:(value:any)=>void, reject)=>{
    axios({
      method: 'get',
      url: 'https://g.nightship.cn/api/json/' + name,
    }).then((res)=>{
      if(res.status == 200){
          resolve(res.data);
      }
      else{
        Vue.$toast.error("网络错误："+res.status);
        reject(res.status)
      }
    }).catch((err)=>{
      Vue.$toast.error("网络错误："+err.message);
      reject(err);
    })

  })
}


// 获取不同大小的图片
export function ImageSize(src:string, size:string){
  if(src){
  src = "https://"+ src;
  const l = /(.*image\/)(.*)(png|jpg|jpeg|bmp)/.exec(src);
  src = l![1] + size +"/" + l![2] + 'jpg';
  return src;
  }
  else{
    return "";
  }
  
}

// 统一上传文件接口
export function UploadFile(url:string, formData: FormData, failToast=true){
  return new Promise((resolve:(value:any)=>void, reject)=>{
    axios.post('https://g.nightship.cn/api/'+url, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
    .then((res)=>{
      if(res.status == 200){
        const result = res.data
        if(result.status == "success"){
          resolve(result.data);
        }else{
          reject(result.errMsg)
          if(failToast){
            Vue.$toast.error("上传失败："+result.errMsg);
          }
        }
      }
      else{
        reject(res.status);
        Vue.$toast.error("网络错误："+res.status);
      }
    }).catch((err)=>{
      reject(err.message);
      Vue.$toast.error("网络错误："+err.message);
    })
  })
}