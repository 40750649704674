import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    meta:{
      title: "首页"
    },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta:{
      title: "关于"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta:{
      title: "登录"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/loginView.vue')
  },
  {
    path: '/my',
    name: 'my',
    meta:{
      title: "个人中心"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/myView.vue')
  },
  {
    path: '/markdown',
    name: 'markdown',
    meta:{
      title: "Markdown格式"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/utils/markdown.vue')
  },
  {
    path: '/presetGroup/:id',
    name: 'presetGroup',
    meta:{
      title: "预设集"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/presetGroup.vue')
  },
  {
    path: '/profile/:uid',
    name: 'profile',
    meta:{
      title: "用户信息"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/profile.vue')
  },
  {
    path: '/preset/:id',
    name: 'preset',
    meta:{
      title: "预设"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/preset.vue')
  },
  {
    path: '/link',
    name: 'link',
    meta:{
      title: "相关链接 - GShade冲印室"
    },
    component: () => import('../views/link.vue')
  },
  {
    path: '/search',
    name: 'searchEmpty',
    meta:{
      title: "搜索 - GShade冲印室"
    },
    component: () => import('../views/search.vue')
  },  {
    path: '/search/:keyword',
    name: 'search',
    meta:{
      title: "搜索 - GShade冲印室"
    },
    component: () => import('../views/search.vue')
  },
  {
    path: '/chineseShader',
    name: 'chineseShader',
    meta:{
      title: "中文版着色器 - GShade冲印室"
    },
    component: () => import('../views/chineseShader.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  if(to.meta && to.meta.title){
    document.title = to.meta.title
  }
  next();
})

export default router
